import React, { FC } from "react";
import { CoordsProvider } from "./Coords";
import {
  CategoriesFilterProvider,
  SearchFilterProvider,
  TypeFilterProvider,
} from "./Filters";
import { FoursquareProvider } from "./Venues/client";
import { NearbyDrawerOpenProvider } from "./Nearby";
import { VenuesProvider } from "./Venues";
import { SubmissionAlerts } from "./Venues/events";
import { AlertProvider, SnackbarAlert } from "./Alert";
import { SuperUserProvider } from "./SuperUser";
import { PasswordProvider, AuthGate } from "./Auth";

export const FoursquareProviders: FC = ({ children }) => {
  return (
    <AlertProvider>
      <PasswordProvider>
        <SuperUserProvider>
        <FoursquareProvider>
          <TypeFilterProvider>
            <SearchFilterProvider>
              <CategoriesFilterProvider>
                <CoordsProvider>
                  <VenuesProvider>
                    <NearbyDrawerOpenProvider>
                      <AuthGate>{children}</AuthGate>
                      <SubmissionAlerts />
                      <SnackbarAlert />
                    </NearbyDrawerOpenProvider>
                  </VenuesProvider>
                </CoordsProvider>
              </CategoriesFilterProvider>
            </SearchFilterProvider>
          </TypeFilterProvider>
        </FoursquareProvider>
        </SuperUserProvider>
      </PasswordProvider>
    </AlertProvider>
  );
};
