import React, { FC, useState, useRef, useCallback } from "react";
import { makeContext } from "../make";
import { useAlert } from "../Alert";

export const {
  Context: SuperUserContext,
  useContext: useSuperUserContext,
  Provider: SuperUserProvider,
} = makeContext("SuperUser", !!localStorage.getItem("super-user"));

export function useSuperUserToggle(
  options = { clickCount: 3, clickTimeout: 200 }
) {
  const count = useRef(0);
  const timer = useRef<any>(null);
  const setSuper = useSuperUserContext()[1];
  const setAlert = useAlert()[1];
  return useCallback(() => {
    count.current++;
    clearTimeout(timer.current);
    if (count.current >= options.clickCount) {
      count.current = 0;
      setSuper((isSuperUser) => {
        localStorage.setItem("super-user", !isSuperUser ? "true" : "");
        setAlert({
          type: !isSuperUser ? "success" : "warning",
          value: !isSuperUser
            ? "You are now a SUPER user"
            : "No longer a SUPER user",
        });
        return !isSuperUser;
      });
    } else {
      timer.current = setTimeout(() => {
        count.current = 0;
      }, options.clickTimeout);
    }
  }, [options, setSuper]);
}
