import Snackbar from "@material-ui/core/Snackbar";
import Alert, { AlertProps } from "@material-ui/lab/Alert";
import React, { ReactNode, useState, useEffect } from "react";
import { makeContext } from "../make";

export const { Provider: AlertProvider, useContext: useAlert } = makeContext<{
  type: AlertProps["severity"];
  value?: ReactNode;
  action?: ReactNode;
} | null>("Alert", null);

export const SnackbarAlert = () => {
  const [open, setOpen] = useState(false);
  const [alert] = useAlert();

  useEffect(() => {
    if (alert) setOpen(true);
  }, [alert]);

  return (
    <Snackbar
      open={open}
      onClose={() => setOpen(false)}
      autoHideDuration={6000}
    >
      <Alert severity={alert?.type} onClose={() => setOpen(false)} action={alert?.action}>
        {alert?.value}
      </Alert>
    </Snackbar>
  );
};
