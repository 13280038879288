import React, { FC, useState } from "react";
import { makeContext } from "../make";
import { useAuthenticateQuery } from "@coin-microservices/foursquare-react-hooks";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useAlert } from "../Alert";

export const getCurrentAuth = () => localStorage.getItem("password") ?? "";
export const {
  Context: PasswordContext,
  useContext: usePasswordContext,
  Provider: PasswordProvider,
} = makeContext("Password", getCurrentAuth());

export const AuthGate: FC = ({ children }) => {
  const [submitted, setSubmitted] = useState(false);
  const [password, setPassword] = usePasswordContext();
  const setAlert = useAlert()[1];
  const { loading, data } = useAuthenticateQuery({
    variables: { password },
    skip: !password,
    onError: (err) => {
      console.log("Error", err);
      setAlert({ type: "error", value: err.message });
    },
  });

  const handleSubmit = (ev: any) => {
    ev.preventDefault();
    const value = ev.target.password.value ?? "";
    localStorage.setItem("password", value);
    setPassword(value);
    setSubmitted(true);
  };

  if (!submitted && loading) return <LinearProgress />;
  if (data?.authenticate) return <>{children}</>;

  return (
    <Box flexGrow={1} display="flex" flexDirection="column">
      {loading ? <LinearProgress /> : null}
      <Box flexGrow={1} />
      <Container maxWidth="sm">
        <Paper>
          <Box p={2}>
            <form onSubmit={handleSubmit}>
              <Typography>Enter Password</Typography>
              <Box my={2}>
                <TextField
                  name="password"
                  type="password"
                  variant="outlined"
                  label="Password"
                  fullWidth
                />
              </Box>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
              >
                Login
              </Button>
            </form>
          </Box>
        </Paper>
      </Container>
      <Box flexGrow={2} />
    </Box>
  );
};
