import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  FC,
} from "react";
import { nearbyUri } from "./client";
import './events'

type IFeature = { type: string; geometry: any; properties: any };
type IGeojson = { type: string; features: IFeature[] };
type IVenueContext = { loading: boolean; data: null | IGeojson; error: string };

const VenuesContext = createContext<null | IVenueContext>(null);

export const useVenuesContext = () => {
  const ctx = useContext(VenuesContext);
  if (!ctx) throw new Error("VenuesProvider not found in tree");
  return ctx;
};

export const VenuesProvider: FC = ({ children }) => {
  const [state, setState] = useState<IVenueContext>({
    loading: false,
    data: null,
    error: "",
  });

  useEffect(() => {
    setState({
      loading: true,
      data: null,
      error: "",
    });
    fetch(`${nearbyUri}/geojson/all`)
      .then((v) => v.json())
      .then((v) => v.result)
      .then((v) =>
        setState({
          loading: false,
          data: v,
          error: "",
        })
      )
      .catch((e) =>
        setState({
          loading: false,
          data: null,
          error: e.message,
        })
      );
  }, []);

  return (
    <VenuesContext.Provider value={state}>{children}</VenuesContext.Provider>
  );
};
