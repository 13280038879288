import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { ErrorBoundary } from "./components/Error/Boundary";
import { SplashPage } from "./components/Layouts/Splash";
import { MainLayout } from "./components/Layouts/Main";
import { FoursquareProviders } from "./providers";

const DataStudio = React.lazy(() => import("./routes/DataStudio"));
const Sessions = React.lazy(() => import("./routes/Sessions"));
const Map = React.lazy(() => import("./routes/Map"));

function App() {
  return (
    <Suspense fallback={<SplashPage loading={true}>Loading...</SplashPage>}>
      <ErrorBoundary>
        <FoursquareProviders>
          <Routes />
        </FoursquareProviders>
      </ErrorBoundary>
    </Suspense>
  );
}

function Routes() {
  return (
    <MainLayout>
      <Switch>
        <Route path="/dashboard" component={DataStudio} />
        <Route
          path={["/sessions/:id", "/session/:id", "/sessions", "/session"]}
          component={Sessions}
        />
        <Route path={["/map/:id", "/map", "/"]} component={Map} />
      </Switch>
    </MainLayout>
  );
}

export default App;
