import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "./providers/Theme";
import { Theme } from "./themes";
import App from "./App";
import "./index.css";

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider>
      <Theme>
        <CssBaseline />
        <App />
      </Theme>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);