import React, { FC } from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { useThemeContext } from "./providers/Theme";

const createTheme = (type: "light" | "dark") =>
  createMuiTheme({
    palette: {
      type,
      info: { main: "#22c3ed" },
      primary: { main: "#22c3ed", contrastText: "#ffffff" },
      secondary: { main: "#ee7268", contrastText: "#ffffff" },
      error: { main: "#ee7268", contrastText: "#ffffff" },
    },
  });

const dark = createTheme("dark");
const light = createTheme("light");

export const LightTheme: FC = ({ children }) => (
  <MuiThemeProvider theme={light}>{children}</MuiThemeProvider>
);

export const DarkTheme: FC = ({ children }) => (
  <MuiThemeProvider theme={dark}>{children}</MuiThemeProvider>
);

export const Theme: FC = ({ children }) => {
  const [theme] = useThemeContext();
  return (
    <MuiThemeProvider theme={theme === "light" ? light : dark}>
      {children}
    </MuiThemeProvider>
  );
};
