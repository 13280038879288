import { useCallback } from 'react'
import { makeContext } from '../make'

export const {
    useContext: useThemeContext,
    Provider: ThemeProvider
} = makeContext('Theme', localStorage.getItem('theme') ?? 'light')

export const useSetTheme = () => {
    const setTheme = useThemeContext()[1]
    return useCallback((theme: string) => {
        localStorage.setItem('theme', theme)
        setTheme(theme)
    }, [setTheme])
}