import { foursquareUri } from "@coin-microservices/foursquare-react-hooks";
import { makeApolloAuthProvider } from "./apollo";

export const nearbyUri =
  // process.env.NODE_ENV === "development"
  //   ? "//localhost:4001"
    // : 
    "https://foursquare-api.coinapp.co";

export const uri =
  // process.env.NODE_ENV === "development"
  //   ? "http://localhost:4000"
    // :
     foursquareUri;

export const FoursquareProvider = makeApolloAuthProvider(uri);
