import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import React, { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useIsMdUp } from "../../hooks/layout";
import { useThemeContext, useSetTheme } from "../../providers/Theme";

import CoinLogo from "../Logos/Coin";
import { useSuperUserToggle } from "../../providers/SuperUser";

const Navbar: FC = () => {
  const isMdUp = useIsMdUp();
  const [theme] = useThemeContext();
  const setTheme = useSetTheme();
  const onClick = useSuperUserToggle()
  return (
    <Toolbar>
      <CoinLogo style={{ height: 44, width: 44, marginRight: 8 }} onClick={onClick} />
      <Typography variant={isMdUp ? "h4" : "h6"}>
        Ground Truth Validation
      </Typography>
      <Box flexGrow={1} />
      {isMdUp ? <NavLinks /> : null}
      <Box ml={1}>
        <IconButton size="small" onClick={() => setTheme(theme === "dark" ? "light" : "dark")}>
          {theme === "dark" ? <Brightness4Icon /> : <Brightness7Icon />}
        </IconButton>
      </Box>
    </Toolbar>
  );
};

const NavLinks = () => {
  const { pathname } = useLocation();
  return (
    <>
      <Box mx={1}>
        <Button
          size="small"
          variant={pathname.indexOf("/dashboard") === 0 ? "contained" : "outlined"}
          component={NavLink}
          disableElevation
          to="/dashboard"
        >
          Dashboard
        </Button>
      </Box>
      <Box mx={1}>
        <Button
          size="small"
          variant={pathname.indexOf("/session") === 0 ? "contained" : "outlined"}
          component={NavLink}
          disableElevation
          to="/sessions"
        >
          Sessions
        </Button>
      </Box>
      <Box mx={1}>
      <Button
        size="small"
        variant={pathname === '/' || pathname.indexOf("/map") === 0 ? "contained" : "outlined"}
        component={NavLink}
        disableElevation
        to="/map"
      >
        Map
      </Button>
      </Box>
    </>
  );
};

export const MainLayout: FC = ({ children }) => {
  const [themeType] = useThemeContext();
  const theme = useTheme();
  return (
    <>
      <Box
        style={{ backgroundColor: theme.palette.background.default, zIndex: 2 }}
      >
        <Navbar />
        <Divider />
      </Box>
      <Box
        flexGrow={1}
        display="flex"
        position="relative"
        flexDirection="column"
        className={`theme-${themeType}`}
      >
        {children}
      </Box>
    </>
  );
};
