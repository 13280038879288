import React, { FC } from "react";
import CoinLogo from "../Logos/Coin";
import XYOLogo from "../Logos/XYO";
import Box, { BoxProps } from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";

export const SplashPage: FC<{ loading?: boolean } & BoxProps> = ({ children, loading, ...rest }) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    position="absolute"
    top={0}
    left={0}
    right={0}
    bottom={0}
    {...rest}
  >
    {loading ? <LinearProgress style={{width: '100%' }} /> : null}
    <Box flexGrow={1} />
    {/* <CoinLogo style={{ height: 100, width: 100, margin: '16px auto' }} /> */}
    {/* <Box mb={2}>
      <Typography variant="h3">GROUND TRUTH VALIDATION</Typography>
    </Box> */}
    <Box display="flex" alignItems="center" mb={2}>
      <XYOLogo style={{ marginRight: 4 }} /> <Typography>Powered by XYO</Typography>
    </Box>
    {children}
    <Box flexGrow={1} />
  </Box>
);
