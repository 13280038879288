import React, {
  createContext,
  FC,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { useQueryString, useQueryStringNumber } from "./Query";

export function makeContext<V>(name: string, initial: V) {
  const Context = createContext<null | [V, Dispatch<SetStateAction<V>>]>(null);
  const useSafeContext = () => {
    const ctx = useContext(Context);
    if (!ctx) throw new Error(`${name} provider not found in tree`);
    return ctx;
  };
  const Provider: FC = ({ children }) => {
    const value = useState(initial);
    return <Context.Provider value={value}>{children}</Context.Provider>;
  };
  return {
    Context,
    Provider,
    useContext: useSafeContext,
  };
}

export function makeQueryStringContext(name: string, initial: string|null) {
  const { Context, useContext } = makeContext<string|null>(name, initial);
  const Provider: FC = ({ children }) => {
    const value = useQueryString(name, initial);
    return <Context.Provider value={value}>{children}</Context.Provider>;
  };
  return {
    Context,
    Provider,
    useContext,
  };
}

export function makeQueryStringNumberContext(name: string, initial: number|null) {
  const { Context, useContext } = makeContext(name, initial);
  const Provider: FC = ({ children }) => {
    const value = useQueryStringNumber(name, initial);
    return <Context.Provider value={value}>{children}</Context.Provider>;
  };
  return {
    Context,
    Provider,
    useContext,
  };
}
