import React, { Component } from "react";
import { SplashPage } from "../Layouts/Splash";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

type P = {};
type S = { hasError: boolean; error?: any };

const reload = () => {
  window.location.reload(true);
};

export class ErrorBoundary extends Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any) {
    if (/Loading chunk (.*) failed./.test(error.message)) {
      this.setState({
        error: {
          message: "New version found, please reload.",
        },
      });
    } else {
      this.setState({ error });
    }
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;
    if (error)
      return (
        <SplashPage>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1} mr={2}>
              {error.message}
            </Box>
            <Button onClick={reload} variant="outlined">
              Reload
            </Button>
          </Box>
        </SplashPage>
      );
    return children;
  }
}
