import { io } from "socket.io-client";
import { nearbyUri } from "./client";
import React, { useEffect } from "react";
import { useAlert } from "../Alert";
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { useApolloClient } from "@apollo/client";

const connection = io(nearbyUri);

export const SubmissionAlerts = () => {
  const setAlert = useAlert()[1];
  const client = useApolloClient();

  useEffect(() => {
    connection.on("validation_submitted", (ev: any) => {
      console.log("VALIDATION SUBMITTED", ev);
      const refetchVenueQueries = () => client.resetStore()
      const action = <Button component={Link} to={`/map/${ev.venueId}`} onClick={refetchVenueQueries}>View</Button>;
      setAlert({ type: 'info', value: 'Validation submitted', action });
    });
  }, []);

  return null;
};
