import qs from "querystring";
import {
  useState,
  useCallback,
  useMemo,
  SetStateAction,
  Dispatch,
} from "react";

const setQueryStringWithoutPageReload = (qsValue: string) => {
  const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${qsValue}`;
  window.history.pushState({ path: newurl }, "", newurl);
};

export function setQueryStringValue(
  key: string,
  value: string | number | boolean | null
) {
  const values = qs.parse(window.location.search.replace(/^\?/, ""));
  const omitted = Object.keys(values).reduce((acc: any, k) => {
    if (k !== key) {
      acc[k] = values[k];
    }
    return acc;
  }, {});
  const newValue = value !== null ? { [key]: value } : {};
  const newQsValue = qs.stringify({ ...omitted, ...newValue });
  console.log(values, newQsValue, key, value);
  setQueryStringWithoutPageReload(newQsValue);
}

export function getQueryStringValue(key: string): string | null {
  const values = qs.parse(window.location.search.replace(/^\?/, ""));
  if (typeof values[key] === "undefined") return null;
  return ([] as string[]).concat(values[key]).join(",");
}

export function useQueryString(key: string, initialValue: string | null) {
  const [value, setValue] = useState<string | null>(
    getQueryStringValue(key) ?? initialValue
  );
  const onSetValue: Dispatch<SetStateAction<string | null>> = useCallback(
    (newValue) => {
      setValue(newValue);
      const v = typeof newValue === "function" ? newValue(value) : newValue;
      setQueryStringValue(key, v);
    },
    [value]
  );

  return [value, onSetValue] as [typeof value, typeof onSetValue];
}

export function useQueryStringNumber(key: string, initialValue: number | null) {
  const [value, setValue] = useQueryString(key, String(initialValue));
  return useMemo(() => {
    const v = Number(value);
    const onSetValue: Dispatch<SetStateAction<number | null>> = (v) =>
      setValue(String(v));
    return [v, onSetValue] as [typeof v, typeof onSetValue];
  }, [value]);
}
