import {
  makeContext,
  makeQueryStringContext,
  makeQueryStringNumberContext,
} from "../make";

export const {
  Context: TypeFilterContext,
  useContext: useTypeFilterContext,
  Provider: TypeFilterProvider,
} = makeContext("TypeFilter", "");

export const {
  Context: SearchFilterContext,
  useContext: useSearchFilterContext,
  Provider: SearchFilterProvider,
} = makeContext("SearchFilter", "");

export const {
  Context: CategoriesFilterContext,
  useContext: useCategoriesFilterContext,
  Provider: CategoriesFilterProvider,
} = makeContext<string[]>("CategoriesFilter", []);

export const {
  Context: PaginationPageContext,
  useContext: usePaginationPageContext,
  Provider: PaginationPageProvider,
} = makeQueryStringNumberContext("page", 1);

export const {
  Context: PaginationPerPageContext,
  useContext: usePaginationPerPageContext,
  Provider: PaginationPerPageProvider,
} = makeQueryStringNumberContext("perPage", 10);

export const {
  Context: SortFieldContext,
  useContext: useSortFieldContext,
  Provider: SortFieldProvider,
} = makeQueryStringContext("field", "created");

export const {
  Context: SortOrderContext,
  useContext: useSortOrderContext,
  Provider: SortOrderProvider,
} = makeQueryStringNumberContext("order", -1);

export const {
  Context: FilterUidContext,
  useContext: useFilterUidContext,
  Provider: FilterUidProvider,
} = makeQueryStringContext("filter.uid", null);

export const {
  Context: FilterCityContext,
  useContext: useFilterCityContext,
  Provider: FilterCityProvider,
} = makeQueryStringContext("filter.city", null);

export const {
  Context: FilterStateContext,
  useContext: useFilterStateContext,
  Provider: FilterStateProvider,
} = makeQueryStringContext("filter.state", null);

export const {
  Context: FilterSourceContext,
  useContext: useFilterSourceContext,
  Provider: FilterSourceProvider,
} = makeQueryStringContext("filter.source", null);

export const {
  Context: FilterInsertedContext,
  useContext: useFilterInsertedContext,
  Provider: FilterInsertedProvider,
} = makeQueryStringContext("filter.inserted", 'true');

export const {
  Context: FilterVerifiedContext,
  useContext: useFilterVerifiedContext,
  Provider: FilterVerifiedProvider,
} = makeQueryStringContext("filter.verified", 'true');

export const {
  Context: FilterClosedContext,
  useContext: useFilterClosedContext,
  Provider: FilterClosedProvider,
} = makeQueryStringContext("filter.closed", 'true');
