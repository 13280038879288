import React, { FC } from "react";
import { setContext } from "apollo-link-context";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  concat,
  HttpLink,
  ApolloLink,
} from "@apollo/client";
import { getCurrentAuth } from "../../providers/Auth";
// import { auth } from "firebase/app";
// import "firebase/auth";

const authLink: ApolloLink = setContext(async (op, { headers }) => {
  // const user = auth().currentUser;
  // const token = user ? await user.getIdToken() : "";
  return {
    headers: {
      ...headers,
      "X-Password": getCurrentAuth(),
    },
  };
}) as any;

const getClient = (uri: string) =>
  new ApolloClient({
    link: concat(authLink, new HttpLink({ uri })),
    cache: new InMemoryCache(),
  });

export const makeApolloAuthProvider = (uri: string) => {
  const client = getClient(uri);
  const Provider: FC = ({ children }) => {
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
  };
  return Provider;
};
